import React, { useContext, useEffect, useRef, useState } from 'react';

import { useField, useFormikContext } from 'formik';
import { flatten, omit, values } from 'lodash';
import { Circle, GoogleMap, InfoWindow, Marker } from 'react-google-maps';

import { Button } from '@components/Button';
import {
  getInnerRadiusKmFromBounds,
  getPlaceFromPosition,
  placeTypePreferences,
} from '@components/Formik/LocationSelector/ClubLocationSelect';
import MapsContext from '@components/Google/GoogleMapsJsApiContext';
import Loading from '@components/Loading';
import Map from '@components/Map';
import { ClubMarkerLocations } from '@components/Map/ClubMarkers';
import CustomMapControl from '@components/Map/CustomMapControl';
import RangeCircle from '@components/Map/RangeCircle';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import ClubSearchResult from './ClubSearchResult';
import ClubSearchSummary from './ClubSearchSummary';
import InfoBoxDetails from './InfoBox';

import {
  ClubSearchFormValues,
  LatLngLiteral,
  SearchClubNumberedMeetingFragment,
} from '@domain/clubs';

import {
  eachWithMeetingLocationNumbers,
  getClubMeetings,
  getMeaningfulFilters,
} from '@use-cases/clubs';

import { useClubSearch } from '@repositories/clubs';

import { useTranslation } from '@external/react-i18next';
import { useAnalytics } from '@hooks/analytics';
import { useMobileLayout } from '@hooks/useMobileLayout';

import { MeetingType } from '@typings/graphql';

type ClubSearchListProps = {
  filters: ClubSearchFormValues;
  currentPage: number;
  dragged: boolean;
  resetFlag: boolean;
  setDragged: (flag: boolean) => void;
  setCurrentPage: (page: number) => void;
};

const ClubSearchList: React.FC<ClubSearchListProps> = ({
  filters,
  currentPage,
  setCurrentPage,
  dragged,
  setDragged,
  resetFlag,
}) => {
  const { t } = useTranslation();

  const topOfTheResultList = useRef<HTMLInputElement>(null);
  const googleMapRef = useRef<GoogleMap>(null);
  const rangeCircleRef = useRef<Circle>(null);

  const { fireTrackingEvent } = useAnalytics();
  const { isMobileLayout } = useMobileLayout();

  const { data, loading } = useClubSearch(filters, currentPage);

  const { submitForm } = useFormikContext();
  const mapsPromise = useContext(MapsContext);

  const [init, setInit] = useState(false);
  const [infoBoxId, setInfoBox] = useState<number | null>(null);
  const [pinnedClub, setPinnedClub] = useState<number | null>(null);
  const [markerAnchor, setMarkerAnchor] = useState<number | null>(null);
  const [, , latitudeHelpers] = useField<number | null>(
    'meetingLocationLatitude'
  );
  const [, , longitudeHelpers] = useField<number | null>(
    'meetingLocationLongitude'
  );
  const [, , locationHelpers] = useField<string>('meetingLocation');
  const [, , rangeHelpers] = useField<number | null>('meetingLocationRange');

  const clubNameFilter =
    Object.keys(getMeaningfulFilters(filters)).indexOf('clubName') > -1;

  const pageSize = 10;

  const itemsToIgnore = [
    'meetingLocationLatitude',
    'meetingLocationLongitude',
    'meetingLocationRange',
    'clubName',
    'distanceUnits',
    filters?.meetingLocation ? '' : 'distance',
  ];

  const numberOfFilters = values(omit(filters, itemsToIgnore)).filter(Boolean)
    .length;

  const noFiltersError = t(
    'club-search.error.no-filters',
    'Please provide at least one search parameter.'
  );

  const clubs = data ? eachWithMeetingLocationNumbers(data.clubs.results) : [];
  const meetings = flatten(clubs.map(getClubMeetings));
  const bounds =
    typeof google !== 'undefined' && new google.maps.LatLngBounds();
  const clubDetails = clubs?.find(
    club =>
      club?.meetings[0]?.xRiLocationNumber === markerAnchor ||
      club?.meetings[0]?.xRiLocationNumber === infoBoxId
  );

  const { fullName, type = null, id, meetings: clubMeeting } =
    clubDetails || {};

  const fitBounds = () => {
    // If the user isn't navigating around map, update the bounds.
    if (
      bounds &&
      !bounds.isEmpty() &&
      googleMapRef.current?.getBounds() !== bounds &&
      !dragged &&
      !rangeCircleRef.current
    ) {
      googleMapRef.current?.fitBounds(bounds);
    }

    if (
      !markerAnchor &&
      !infoBoxId &&
      !dragged &&
      rangeCircleRef.current?.getBounds()
    ) {
      googleMapRef.current?.fitBounds(rangeCircleRef.current.getBounds());
    }
  };

  const markers = meetings
    .map(({ geo, xRiLocationNumber, categories }) => {
      if (
        geo?.latitude &&
        geo?.longitude &&
        xRiLocationNumber &&
        categories?.includes('PHYSICAL')
      ) {
        if (bounds) {
          bounds.extend({
            lat: geo.latitude,
            lng: geo.longitude,
          });
        }
        const isHighlighted =
          (pinnedClub || markerAnchor || infoBoxId) === xRiLocationNumber;
        return (
          <ClubMarkerLocations
            key={xRiLocationNumber}
            clubNumber={xRiLocationNumber}
            setInfoBox={id => {
              setInfoBox(id);
              if (markerAnchor) {
                setMarkerAnchor(null);
              }
            }}
            isHighlighted={isHighlighted}
            setMarkerAnchor={setMarkerAnchor}
            location={{
              lat: geo.latitude,
              lng: geo.longitude,
            }}
          />
        );
      }

      return null;
    })
    .filter(Boolean);

  useEffect(() => {
    fitBounds();
  }, [markers, rangeCircleRef.current]);

  useEffect(() => {
    if (markerAnchor || infoBoxId) {
      setPinnedClub(null);
    }
  }, [markerAnchor, infoBoxId]);

  // Re-render the map after loading to avoid gray box.
  useEffect(() => {
    if (data && !loading) {
      setInit(false);
    }
  }, [data, loading]);

  const closeHandler = () => {
    setMarkerAnchor(null);
    setInfoBox(null);
  };

  useEffect(() => {
    // Fire analytics event when we have the initial search results and a search term.
    if (data && currentPage === 1) {
      if (data.clubs.totalCount !== 0) {
        fireTrackingEvent('searchResults', {
          searchTerm: filters.clubName,
          searchTotalResults: data.clubs.totalCount,
          searchType: 'Club Search',
          searchFilters: filters,
        });
      } else {
        // Separate out the event if zero results
        fireTrackingEvent('searchResultsEmpty', {
          searchTerm: filters.clubName,
          searchType: 'Club Search',
          searchFilters: filters,
        });
      }
    }

    // Scroll to top when data is updated
    const googleContainer: HTMLDivElement | null = document.querySelector(
      '#googleMapContainer'
    );

    if (googleContainer) {
      googleContainer.scrollIntoView({ block: 'center' });
    } else {
      topOfTheResultList.current?.scrollIntoView();
    }
  }, [data]);

  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const isLocationBoundSearch = Boolean(
    filters.meetingLocationLatitude &&
      filters.meetingLocationLongitude &&
      filters.meetingLocationRange
  );
  const hideMap = filters.meetingType === 'Online';

  const searchCenter: undefined | LatLngLiteral = isLocationBoundSearch
    ? {
        lat: parseFloat(String(filters.meetingLocationLatitude)),
        lng: parseFloat(String(filters.meetingLocationLongitude)),
      }
    : undefined;

  const handleDrag = () => setDragged(true);

  const handleClick = () => {
    if (googleMapRef.current) {
      const mapBounds = googleMapRef.current.getBounds();
      const center = googleMapRef.current.getCenter();
      const meetingLocationLatitude = center.lat();
      const meetingLocationLongitude = center.lng();
      const meetingLocationRange = getInnerRadiusKmFromBounds(mapBounds);

      latitudeHelpers.setValue(meetingLocationLatitude);
      longitudeHelpers.setValue(meetingLocationLongitude);
      rangeHelpers.setValue(meetingLocationRange);
      setDragged(false);

      mapsPromise
        .then(
          getPlaceFromPosition.bind(
            null,
            {
              coords: {
                latitude: meetingLocationLatitude,
                longitude: meetingLocationLongitude,
              },
            },
            placeTypePreferences
          )
        )
        .then(place => locationHelpers.setValue(place.formatted_address));

      submitForm();
    }
  };

  const handleIdle = () => {
    if (!init) {
      setInit(true);
      fitBounds();
    }
  };

  const mapCenterHandler = () => {
    if (infoBoxId && !markerAnchor) setMarkerAnchor(infoBoxId);
  };

  const renderMap = () => (
    <Map
      googleMapRef={googleMapRef}
      onDragStart={handleDrag}
      onIdle={handleIdle}
      defaultCenter={searchCenter}
      onCenterChanged={mapCenterHandler}
      onClick={closeHandler}
      defaultOptions={{ fullscreenControl: isMobileLayout }}
    >
      {dragged && (
        <CustomMapControl map={googleMapRef.current}>
          <div className="absolute bottom-0 h-18 w-full flex justify-center items-center py-3">
            <div className="absolute bottom-0 left-0 w-full h-18 bg-white opacity-75" />
            <Button className="z-10" clickHandler={handleClick} small mapButton>
              {t('search.clubs.redo-submit-label', 'redo search in this area')}
            </Button>
          </div>
        </CustomMapControl>
      )}
      {searchCenter && <Marker position={searchCenter} />}
      {markers}
      {(markerAnchor || infoBoxId) && type && fullName && id && clubMeeting && (
        <InfoWindow
          zIndex={2}
          position={
            new google.maps.LatLng(
              clubMeeting[0]?.geo?.latitude || 0,
              clubMeeting[0]?.geo?.longitude || 0
            )
          }
          onCloseClick={closeHandler}
          options={{
            pixelOffset: new google.maps.Size(-4, -40),
          }}
        >
          <div>
            <InfoBoxDetails
              fullName={fullName}
              type={type}
              id={id}
              meetings={clubMeeting}
            />
          </div>
        </InfoWindow>
      )}
      <RangeCircle center={searchCenter} rangeCircleRef={rangeCircleRef} />
    </Map>
  );

  if (numberOfFilters === 0 && !clubNameFilter) {
    return <p className="py-12 px-6">{noFiltersError}</p>;
  }

  if (resetFlag) return null;

  const getMeetingsByFilter = (
    meetings: SearchClubNumberedMeetingFragment[]
  ) => {
    const { meetingType } = filters;

    if (
      meetingType === MeetingType.Online ||
      meetingType === MeetingType.Physical
    ) {
      return meetings.filter(({ categories }) =>
        categories?.some(
          category => category.toLowerCase() === meetingType.toLowerCase()
        )
      );
    }

    return meetings;
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        !hideMap &&
        (isLocationBoundSearch || markers.length > 0) && (
          <div id="googleMapContainer">{renderMap()}</div>
        )
      )}

      {!loading && data ? (
        <>
          <ResultsList
            className="py-8 px-6 desktop:pr-0"
            listId="search-results-list"
            summary={
              <ClubSearchSummary
                showing={data.clubs.results.length}
                total={data.clubs.totalCount}
                clubName={filters.clubName}
                numberOfFilters={numberOfFilters}
                ref={topOfTheResultList}
              />
            }
          >
            {clubs.map((club, index) => (
              <ResultsListItem key={club.id}>
                {/* Add the "search rank" to results (which number link is clicked in results) */}
                <ClubSearchResult
                  id={club.id}
                  name={club.name}
                  type={club.type}
                  meetings={getMeetingsByFilter(club.meetings)}
                  fullName={club.fullName}
                  setPinnedClub={id => {
                    setPinnedClub(id);
                    if (markerAnchor) {
                      setMarkerAnchor(null);
                    }
                    if (infoBoxId) {
                      setInfoBox(null);
                    }
                  }}
                  searchRank={pageSize * (currentPage - 1) + (index + 1)}
                />
              </ResultsListItem>
            ))}
          </ResultsList>
          {data.clubs.totalCount > 0 && (
            <Pagination
              pageSize={pageSize}
              page={currentPage}
              totalCount={data.clubs.totalCount}
              pageHandler={pageHandler}
            />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ClubSearchList;
