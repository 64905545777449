import React from 'react';

import Link from '@components/Link';

import { SearchClubNumberedMeetingFragment } from '@domain/clubs';
import {
  MEETING_TYPE_MEETING,
  MEETING_TYPE_ONLINE,
  MEETING_TYPE_PHYSICAL,
} from '@domain/search';

import {
  getMeetingTypeLabel,
  getMeetingTypeTranslation,
} from '@use-cases/clubs';

import { trimClubFullName } from '@utils/club-name';
import { getClubTypeName } from '@utils/getClubTypeName';
import { getDayLabel } from '@utils/getDayLabel';

import { useTranslation } from '@external/react-i18next';
import { useAnalytics } from '@hooks/analytics';

import { DIS } from '@typings/dis';
import { Enum, From, mapEnum } from '@typings/map-enum';

type InfoBoxDetailsProps = {
  fullName: string;
  type: string;
  id: string;
  meetings: SearchClubNumberedMeetingFragment[];
};

const InfoBoxDetails: React.FC<InfoBoxDetailsProps> = ({
  fullName,
  type,
  meetings,
  id,
}) => {
  const { fireTrackingEvent } = useAnalytics();
  const { t } = useTranslation();
  const clubPath = `/club/${id}/details`;

  const clubMeeting = meetings
    ? meetings.map(meeting => {
        const {
          url,
          xRiWeekday,
          description,
          xRiLanguageName,
          categories,
          xRiTime,
          geo,
        } = meeting;
        const meetingType: string =
          (categories && getMeetingTypeLabel(categories)) ||
          MEETING_TYPE_MEETING;
        const localizedMeetingType = getMeetingTypeTranslation(t, meetingType);
        const isPhysical = meetingType === MEETING_TYPE_PHYSICAL;

        return (
          <div
            key={`${geo?.latitude} ${geo?.longitude}`}
            data-label="SearchResult"
            className="flex flex-col w-full mt-0"
          >
            <p className="text-small">
              {description}
              <br />
              {t(
                'club-search.meetings.description-physical',
                'Meets {{ day }}, {{ time }}',
                {
                  day: getDayLabel(t, String(xRiWeekday)),
                  time: xRiTime,
                }
              )}

              {xRiLanguageName !== null && xRiLanguageName}
            </p>
            {url && !isPhysical && (
              <p className="text-small">{localizedMeetingType}</p>
            )}
          </div>
        );
      })
    : [];

  const firstMeeting = meetings?.find(meeting => meeting.xRiAddress);

  const { xRiAddress, categories } = firstMeeting || {
    xRiAddress: null,
    categories: null,
  };

  const ClubNameType = type
    ? getClubTypeName(t, mapEnum(From.DIS, Enum.ClubType, type as DIS.ClubType))
    : '';

  const fullNameWithType = `${trimClubFullName(fullName)} (${ClubNameType})`;

  const city = xRiAddress?.city || '';
  const state = xRiAddress?.state ? `, ${xRiAddress.state}` : '';
  const country = xRiAddress?.country ? `, ${xRiAddress.country}` : '';

  const isOnline = categories
    ? getMeetingTypeLabel(categories) === MEETING_TYPE_ONLINE
    : false;

  const trackClick = (destination: string) => {
    fireTrackingEvent('searchResultClicked', {
      searchResultDestination: destination,
      searchType: 'Club Search',
      searchResultIndex: 0,
    });
  };

  return (
    <div data-label="SearchResult" className="flex flex-col w-3/4 pt-4">
      <div className="pl-4">
        <h3 className="mb-2">
          <Link
            role="link"
            to={clubPath}
            onClick={() => trackClick(clubPath)}
            target="_blank"
            useExternalLink
          >
            {fullNameWithType}
          </Link>
        </h3>

        {xRiAddress && !isOnline && (
          <p className="text-small font-bold">{`${city}${state}${country}`}</p>
        )}
        {clubMeeting}
      </div>
    </div>
  );
};

export default InfoBoxDetails;
