import React, { Dispatch, FC, SetStateAction } from 'react';

import { Marker } from 'react-google-maps';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import mapPinIcon from '../../assets/icons/ui/map-pin.svg';

import { LatLngLiteral } from '@domain/clubs';

import { useMobileLayout } from '@hooks/useMobileLayout';

type ClubMarkerProps = {
  location: LatLngLiteral;
  clubNumber?: number | null;
  setInfoBox: Dispatch<SetStateAction<number | null>>;
  isHighlighted: boolean;
  setMarkerAnchor: Dispatch<SetStateAction<number | null>>;
};

export const ClubMarkerCenter: FC<{ location: LatLngLiteral }> = ({
  location,
}) => {
  const mobile = window.innerWidth < 800;
  const resizedPin = mobile
    ? new google.maps.Size(31, 31)
    : new google.maps.Size(41, 41);

  const clubPinCenter = {
    url: mapPinIcon,
    scaledSize: resizedPin,
  };

  return <Marker position={location} icon={clubPinCenter} />;
};

export const ClubMarkerLocations: FC<ClubMarkerProps> = ({
  location,
  clubNumber = null,
  setInfoBox,
  setMarkerAnchor,
  isHighlighted,
}) => {
  const { isMobileLayout } = useMobileLayout();
  const isTwoDigitNumber = clubNumber && clubNumber > 9;
  const iconColor = isHighlighted ? '#19519C' : '#872175';
  const pinnedIconSize = isHighlighted
    ? new google.maps.Size(50, 50)
    : new google.maps.Size(42, 42);
  const pinnedMobileIconSize = isHighlighted
    ? new google.maps.Size(35, 35)
    : new google.maps.Size(32, 32);
  const resizedPin = isMobileLayout ? pinnedMobileIconSize : pinnedIconSize;

  const xAxisTextPoint = isTwoDigitNumber ? 8.5 : 3.5;
  const xAxisTextPointMobile = isTwoDigitNumber ? 8.75 : 4;

  const labelText = isMobileLayout
    ? new google.maps.Point(xAxisTextPointMobile, 28)
    : new google.maps.Point(xAxisTextPoint, 36);

  // absolute url path does not work for svg
  // svg should be added through SVG Path props
  // or through alternative data URL with encoded svg
  const svg =
    '<svg height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    `<path d="M17.209 0C11.24 0 5.83398 4.96271 5.83398 11.0863C5.83398 17.2083 10.7665 24.5175 17.209 35C23.6515 24.5175 28.584 17.2083 28.584 11.0863C28.584 4.96271 23.1794 0 17.209 0Z" fill='${iconColor}'/>\n` +
    '</svg>';

  /** To make it work on IE 11 need to add:
   * - "scaledSize" into icon props
   * - "optimized = false" into Marker props */
  const clubPinLocations = {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
    scaledSize: resizedPin,
    origin: new google.maps.Point(0, 0),
    size: resizedPin,
  };

  return (
    <MarkerWithLabel
      position={location}
      icon={clubPinLocations}
      labelAnchor={labelText}
      optimized={false}
      zIndex={isHighlighted ? 999 : undefined}
      clickable
      labelStyle={{
        fontSize: isMobileLayout ? '12px' : '14px',
        color: '#FFFFFF',
      }}
      onMouseOver={() => setInfoBox(clubNumber)}
      onClick={() => setMarkerAnchor(clubNumber)}
    >
      <div>{clubNumber}</div>
    </MarkerWithLabel>
  );
};
