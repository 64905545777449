import React, { Dispatch, SetStateAction } from 'react';

import Link from '@components/Link';
import ClubMeetingIcon from '@presenters/web/pages/ClubSearch/ClubSearchList/ClubMeetingIcon';

import { SearchClubNumberedMeetingFragment } from '@domain/clubs';
import {
  MEETING_TYPE_MEETING,
  MEETING_TYPE_ONLINE,
  MEETING_TYPE_PHYSICAL,
} from '@domain/search';

import {
  getMeetingTypeLabel,
  getMeetingTypeTranslation,
} from '@use-cases/clubs';

import { getDayLabel } from '@utils/getDayLabel';

import { useTranslation } from '@external/react-i18next';

interface ClubMeetingProps extends SearchClubNumberedMeetingFragment {
  setPinnedClub: Dispatch<SetStateAction<number | null>>;
}

const ClubMeeting: React.FC<ClubMeetingProps> = ({
  description,
  xRiWeekday,
  xRiLanguageName,
  xRiLocationNumber,
  xRiTime,
  categories,
  comment,
  url,
  setPinnedClub,
}) => {
  const { t } = useTranslation();

  const meetingType: string =
    (categories && getMeetingTypeLabel(categories)) || MEETING_TYPE_MEETING;
  const localizedMeetingType = getMeetingTypeTranslation(t, meetingType);

  const isOnline = meetingType === MEETING_TYPE_ONLINE;
  const isPhysical = meetingType === MEETING_TYPE_PHYSICAL;

  const clickHandler = () => {
    const googleContainer: HTMLDivElement | null = document.querySelector(
      '#googleMapContainer'
    );
    if (googleContainer) {
      googleContainer.scrollIntoView({ block: 'center' });
    }
    setPinnedClub(xRiLocationNumber || null);
  };

  return (
    <div data-label="SearchResult" className="flex w-full mt-4">
      <button type="button" onClick={clickHandler}>
        <ClubMeetingIcon
          online={isOnline}
          physical={isPhysical}
          locationNumber={xRiLocationNumber}
        />
      </button>
      <div className="self-stretch w-3/4">
        <p className="mb-0 text-small">
          {t('club-search.meetings.title', '{{title}}', {
            title: description,
          })}
        </p>
        <p className="mb-0 text-small">
          {t(
            'club-search.meetings.description-physical',
            'Meets {{ day }}, {{ time }}',
            {
              type: localizedMeetingType,
              day: getDayLabel(t, String(xRiWeekday)),
              time: xRiTime,
            }
          )}

          {xRiLanguageName !== null &&
            t(
              'club-search.meetings.description-language-name',
              `, ${xRiLanguageName}`
            )}
        </p>
        {url && !isPhysical ? (
          <p className="text-small">
            {t(
              'club-search.meetings.description-online-hybrid-link',
              '{{type}} at ',
              {
                type: localizedMeetingType,
              }
            )}
            <Link to={url}>{url}</Link>
          </p>
        ) : (
          ''
        )}
        {!url && comment && <p className="text-small">{comment}</p>}
      </div>
    </div>
  );
};
export default ClubMeeting;
