import * as React from 'react';

import classNames from 'classnames';

import {
  FormElementDescription,
  FormElementErrorMessage,
  FormElementRequiredMarker,
  LabelHelper,
} from '../FormElement';

import { useTranslation } from '@external/react-i18next';

export type RadioOption = {
  value: string;
  label: string;
  disabled?: boolean;
  description?: JSX.Element;
};

export const Radios: React.FC<{
  legend?: string;
  legendHidden?: boolean;
  id: string;
  name: string;
  options?: RadioOption[];
  value?: string;
  className?: string;
  classes?: {
    legend?: string;
    optionWrapper?: string;
    optionsWrapper?: string;
    radioBtn?: string;
  };
  withOrs?: boolean;
  noMargins?: boolean;
  required?: boolean;
  disabled?: boolean;
  description?: React.ReactNode;
  error?: boolean;
  errorMessage?: string;
  changeHandler?: (value: string) => void;
}> = ({
  legend,
  legendHidden,
  id,
  name,
  options,
  value,
  changeHandler = () => {},
  className,
  classes = { radioBtn: 'z-10' },
  withOrs,
  noMargins,
  required,
  disabled,
  description,
  error,
  errorMessage,
}) => {
  const { t } = useTranslation();
  return (
    <fieldset
      className={classNames(className, {
        'block mb-3': !noMargins,
      })}
    >
      {legend && (
        <legend
          className={classNames(classes?.legend, 'text-small font-bold mb-2', {
            'sr-only': legendHidden,
            'text-gray-300': disabled,
            'text-gray-600': !disabled,
          })}
        >
          {legend}
          <FormElementRequiredMarker required={required} />
        </legend>
      )}
      <div
        className={classNames(classes?.optionsWrapper, {
          RadioWithOrs: withOrs,
        })}
      >
        {options?.map(
          (
            {
              label: optionLabel,
              value: optionValue,
              disabled: optionDisabled,
              description: optionDescription,
            },
            index
          ) => {
            const optionId = `${id}-${optionValue}`;
            const isChecked = value === optionValue;
            return (
              <React.Fragment key={optionId}>
                {index > 0 && withOrs && (
                  <div
                    className={classNames('RadioOr', classes?.optionWrapper)}
                  >
                    {t('radios.or', 'or')}
                  </div>
                )}
                <div
                  className={classNames('RadioOption', classes?.optionWrapper)}
                >
                  <LabelHelper
                    id={optionId}
                    label={optionLabel}
                    labelAfter
                    disabled={optionDisabled || disabled}
                    required={required && legendHidden}
                    error={error}
                    isFontBold={false}
                    classes={{
                      label: classNames('RadioLabel', {
                        RadioLabelError: error,
                        'font-bold': isChecked,
                      }),
                    }}
                  >
                    <input
                      type="radio"
                      id={optionId}
                      name={name}
                      disabled={optionDisabled || disabled}
                      required={required}
                      value={optionValue}
                      checked={isChecked}
                      onChange={e => {
                        changeHandler(e.target.value);
                      }}
                      className={classNames('RadiosInput', classes?.radioBtn)}
                      data-testid={`radios-input-${optionValue}`}
                    />
                  </LabelHelper>
                  {optionDescription}
                </div>
              </React.Fragment>
            );
          }
        )}
      </div>
      {description && (
        <FormElementDescription id={id}>{description}</FormElementDescription>
      )}
      {error && (
        <FormElementErrorMessage id={id}>
          {errorMessage}
        </FormElementErrorMessage>
      )}
    </fieldset>
  );
};
