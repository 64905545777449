import React from 'react';

import { useFormikContext } from 'formik';
import { Circle } from 'react-google-maps';

import { ClubSearchFormValues, LatLngLiteral } from '@domain/clubs';

type RangeCircleProps = {
  center?: LatLngLiteral;
  rangeCircleRef: React.MutableRefObject<Circle | null>;
};

const RangeCircle: React.FC<RangeCircleProps> = ({
  center,
  rangeCircleRef,
}) => {
  const { values } = useFormikContext<ClubSearchFormValues>();

  const {
    meetingLocationLatitude = 0,
    meetingLocationLongitude = 0,
    meetingLocationRange,
  } = values;

  if (!meetingLocationLatitude && !meetingLocationLongitude) {
    return null;
  }

  // Circle component waits for "radius" prop in metres
  const circleRadius = meetingLocationRange
    ? Math.round(meetingLocationRange) * 1000
    : 0;

  return (
    <Circle
      ref={rangeCircleRef}
      center={center}
      radius={circleRadius}
      options={{
        strokeColor: '#fff',
        fillColor: '#f6f7f7',
        fillOpacity: 0.5,
        strokeWeight: 3,
      }}
    />
  );
};

export default RangeCircle;
