import React from 'react';

import { HybridPin, OnlinePin, PhysicalPin } from '@components/ClubPin';

type ClubMeetingIconProps = {
  online?: boolean;
  physical?: boolean;
  locationNumber?: number;
};

const ClubMeetingIcon: React.FC<ClubMeetingIconProps> = ({
  online,
  physical,
  locationNumber: xRiLocationNumber,
}) => {
  if (online) {
    return <OnlinePin />;
  }

  return physical ? (
    <PhysicalPin locationNumber={xRiLocationNumber} />
  ) : (
    <HybridPin locationNumber={xRiLocationNumber} />
  );
};

export default ClubMeetingIcon;
