import { gql } from '@apollo/client';

export const fetchDisplayNames = gql`
  query fetchDisplayNames($displayLanguage: String!, $displayType: String!) {
    displayNames(displayLanguage: $displayLanguage, displayType: $displayType) {
      id
      name
      shortCode
    }
  }
`;
