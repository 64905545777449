import React from 'react';

import Select, { SelectProps } from '../Select';

import { useTranslation } from '@external/react-i18next';

import { OrganizationBase } from '@typings/operations';

const RotaractTypeSelect: React.FC<Omit<SelectProps, 'options'>> = props => {
  const { t } = useTranslation();

  const options = [
    {
      label: t('club-organization-base.community', 'Community'),
      value: OrganizationBase.Community,
    },
    {
      label: t('club-organization-base.university', 'University'),
      value: OrganizationBase.University,
    },
  ];

  return <Select {...props} searchable options={options} />;
};

export default RotaractTypeSelect;
