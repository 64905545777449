import React from 'react';

import Select, { SelectProps } from '../Select';

import { useTranslation } from '@external/react-i18next';

const ClubTypeSelect: React.FC<Omit<SelectProps, 'options'>> = props => {
  const { t } = useTranslation();

  const options = [
    {
      label: t('club-type.rotary-club', 'Rotary Club'),
      value: 'Rotary_Club',
    },
    {
      label: t('club-type.satellite-club', 'Rotary Satellite Club'),
      value: 'Satellite_Club',
    },
    {
      label: t('club-type.rotaract-club', 'Rotaract Club'),
      value: 'Rotaract_Club',
    },
    {
      label: t('club-type.rotaract-satellite-club', 'Rotaract Satellite Club'),
      value: 'Rotaract_Satellite_Club',
    },
  ];

  return <Select {...props} searchable options={options} />;
};

export default ClubTypeSelect;
