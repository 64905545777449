import React from 'react';

// Components
import Select, { SelectProps } from '../Select';
import Loading from '@components/Loading';

// Hooks
import { useTranslation } from '@external/react-i18next';
import { useDisplayNames } from '@hooks/useDisplayNames';
import { useErrorHandling } from '@use-cases/notifications';

// Types
import { DisplayNames } from '@typings/operations';

// Constants
import { DISPLAY_TYPE } from '@domain/common';

const generateOptions = (displayNames: DisplayNames[]) =>
  displayNames?.map(({ name, id }: DisplayNames) => ({
    label: name,
    value: id,
  })) || [];

type Props = Omit<SelectProps, 'options'>;

const LanguageSelect: React.FC<Props> = props => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useDisplayNames(
    i18n.language,
    DISPLAY_TYPE.LANGUAGE
  );
  useErrorHandling(error?.message, !!error, 'fetch-languages.error');

  if (loading || !data) {
    return <Loading inline small />;
  }

  const languageOptions = generateOptions(data.displayNames).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return <Select searchable options={languageOptions} {...props} />;
};

export default LanguageSelect;
