import React from 'react';

import { GoogleMap, GoogleMapProps, withGoogleMap } from 'react-google-maps';

export interface MapProps extends GoogleMapProps {
  googleMapRef: React.MutableRefObject<GoogleMap | null>;
}

const BaseMap = withGoogleMap((props: MapProps) => {
  const { googleMapRef, defaultCenter, ...googleMapProps } = props;

  return (
    <div className="relative">
      <GoogleMap
        ref={googleMapRef}
        options={{
          streetViewControl: false,
          fullscreenControl: true,
          mapTypeControl: false,
          zoomControl: true,
          // TODO fix issue around zoom and fix bounds
          // RIGHT_TOP position for zoomControl. The default position is TOP_LEFT = 1
          zoomControlOptions: { position: 7 },
          maxZoom: 16,
        }}
        defaultCenter={defaultCenter}
        {...googleMapProps}
      />
    </div>
  );
});

const Map: React.FC<MapProps> = props => (
  <BaseMap
    containerElement={<div className="map-max-width map-height" />}
    mapElement={<div className="h-full" />}
    {...props}
  />
);

export default Map;
