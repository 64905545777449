import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client';

import { fetchDisplayNames } from '@queries/fetchDisplayNames';

import {
  FetchDisplayNamesQuery,
  FetchDisplayNamesQueryVariables,
} from '@typings/operations';

export const useDisplayNames = (displayLanguage: string, displayType: string) =>
  useQuery<FetchDisplayNamesQuery, FetchDisplayNamesQueryVariables>(
    fetchDisplayNames,
    {
      variables: {
        displayLanguage,
        displayType,
      },
    }
  );

export const useDisplayNamesFromCache = (
  client: ApolloClient<InMemoryCache>,
  isDisplayNamesFromCache: boolean,
  displayLanguage: string,
  displayType: string
) =>
  isDisplayNamesFromCache &&
  client.readQuery({
    query: fetchDisplayNames,
    variables: {
      displayLanguage,
      displayType,
    },
  });
