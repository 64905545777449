import React, { forwardRef } from 'react';

import { ClubSearchFormValues } from '@domain/clubs';

import { useTranslation } from '@external/react-i18next';

type ClubSearchSummaryProps = ClubSearchFormValues & {
  showing: number;
  total: number;
  numberOfFilters?: number;
};

const ClubSearchSummary = forwardRef<
  HTMLParagraphElement,
  ClubSearchSummaryProps
>(({ showing, total, clubName, numberOfFilters, children }, ref) => {
  const { t } = useTranslation();

  const getSearchMessage = () => {
    if (!clubName && !numberOfFilters) {
      return t(
        'club-search.resultsSummary.showing',
        'Showing {{ showing }} of {{ total }} results',
        { showing, total, count: total }
      );
    }

    if (!numberOfFilters) {
      return t(
        'club-search.resultsSummary.showingForClubName',
        'Showing {{ showing }} of {{ total }} results for <strong>{{ clubName }}</strong>',
        { showing, total, clubName, count: total }
      );
    }

    if (numberOfFilters === 1) {
      return !clubName
        ? t(
            'club-search.resultsSummary.showingWithSingleFilter',
            'Showing {{ showing }} of {{ total }} results with <strong>{{ numberOfFilters }}</strong> filter applied',
            { showing, total, numberOfFilters, count: total }
          )
        : t(
            'club-search.resultsSummary.showingForNameWithSingleFilter',
            'Showing {{ showing }} of {{ total }} results for <strong>{{ clubName }}</strong> with <strong>{{ numberOfFilters }}</strong> filter applied',
            { showing, total, clubName, numberOfFilters, count: total }
          );
    }

    return !clubName
      ? t(
          'club-search.resultsSummary.showingWithMultipleFilters',
          'Showing {{ showing }} of {{ total }} results with <strong>{{ numberOfFilters }}</strong> filters applied',
          { showing, total, numberOfFilters, count: total }
        )
      : t(
          'club-search.resultsSummary.showingForNameWithMultipleFilters',
          'Showing {{ showing }} of {{ total }} results for <strong>{{ clubName }}</strong> with <strong>{{ numberOfFilters }}</strong> filters applied',
          { showing, total, clubName, numberOfFilters, count: total }
        );
  };

  return (
    <p className="text-xs leading-xs mb-0" ref={ref}>
      {getSearchMessage()}
      {children}
    </p>
  );
});

export default ClubSearchSummary;
