import * as React from 'react';

import Icon from '../Icon';

export const OnlinePin: React.FC<{
  locationNumber?: number;
}> = () => {
  return (
    <div
      className="online-pin-ml h-6 w-8 desktop:h-8 desktop:w-10 ml-5 mt-1 mr-8"
      data-cy="online"
      data-testid="online"
    >
      <Icon name="ui/online-meeting" size="100%" color="gray-600" />
    </div>
  );
};

export const PhysicalPin: React.FC<{
  locationNumber?: number;
}> = ({ locationNumber }) => {
  return (
    <div
      className="h-8 w-8 desktop:h-10 desktop:w-10 ml-4 mt-1 mr-8"
      data-cy="physical"
      data-testid="physical"
    >
      {locationNumber ? (
        <div className="h-8 w-8 desktop:h-10 desktop:w-10 relative">
          <Icon name="ui/map-pin-numbered" size="100%" color="gray-600" />
          <span className="absolute inset-x-0 top-0 flex items-center justify-center text-white text-xs font-bold">
            {locationNumber}
          </span>
        </div>
      ) : (
        <Icon
          name="ui/map-pin"
          size="100%"
          color="gray-600"
          className="relative"
        />
      )}
    </div>
  );
};

export const HybridPin: React.FC<{
  locationNumber?: number;
}> = ({ locationNumber }) => {
  return (
    <div
      className="hybrid-pin-ml h-8 w-10 desktop:h-10 desktop:w-12 mr-8 mt-1 desktop:mr-4"
      data-cy="hybrid"
      data-testid="hybrid"
    >
      {locationNumber ? (
        <div className="h-8 w-10 desktop:h-10 desktop:w-12 relative">
          <Icon
            name="ui/on-and-offline-meeting-2c"
            size="100%"
            color="gray-600"
          />
          <span className="absolute top-0 left-0 flex items-center justify-center text-white text-xs font-bold hybrid-num-pl">
            {locationNumber}
          </span>
        </div>
      ) : (
        <Icon name="ui/on-and-offline-meeting" size="100%" color="gray-600" />
      )}
    </div>
  );
};
