import React, { useEffect, useState } from 'react';

import ReactDOM from 'react-dom';
import { GoogleMap } from 'react-google-maps';
import { MAP } from 'react-google-maps/lib/constants';

type CustomMapControlProps = {
  map: GoogleMap | null;
  children: React.ReactElement;
  position?: google.maps.ControlPosition;
};

const CustomMapControl: React.FC<CustomMapControlProps> = ({
  map,
  children,
  position = google.maps.ControlPosition.BOTTOM_CENTER,
}) => {
  const [controlDiv] = useState(() => {
    const container = document.createElement('div');
    container.setAttribute('style', 'left: 0; right: 0;');
    return container;
  });

  useEffect(() => {
    const controls = map?.context?.[MAP]?.controls?.[position];

    const index = controls?.length;
    controls?.push(controlDiv);

    return () => {
      controls?.removeAt(index);
    };
  }, []);

  return ReactDOM.createPortal(children, controlDiv);
};

export default CustomMapControl;
